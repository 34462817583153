import React, {Component} from 'react';
export default class MousePointer extends Component {
  constructor() {
    super();
    this.cursor = React.createRef();
  }

  componentDidMount() {
    this.renderMousePointer();
  }

  renderMousePointer = () => {
    document.addEventListener("mousemove", (e) => {
      let {pageX, pageY} = e
      pageX = pageX - 46
      pageY = pageY - 46
      let styleVal = `-webkit-transform: translate(${pageX}px, ${pageY}px); transform: translate(${pageX}px, ${pageY}px);`
      const cb = () => {this.cursor.current.setAttribute("style", styleVal)}
      requestAnimationFrame(cb)
    });
    // document.addEventListener("click" ,() => {
    //   this.cursor.current.classList.add("expand");
    //   setTimeout(() => {
    //     this.cursor.current.classList.remove("expand");
    //   }, 300);
    // });
  }

  render() {
    return <div ref={this.cursor} className="cursor"><i className="rightArrow" /></div>
  }
}