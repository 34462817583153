import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import {NavbarMenu} from "../../data/navbar";
import jpgLogo from "../../assets/images/logo.jpg";
export default class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleMenu: false,
      visible: false,
    };
  }

  static getDerivedStateFromProps(newProps) {
    return newProps;
  }

  onNavItemClick = (e) => {
    this.props.onClick(e);
  }

  mobileMenuClick = () => {
    this.setState((prevState) => ({
      toggleMenu: !prevState.toggleMenu
    }));
    this.props.onMobileMenuClick(!this.state.toggleMenu);
  }

  render() {
    const {toggleMenu, visible} = this.state;
    const navItems = NavbarMenu.map((item, index) => {
      return (
        <li key={`navItem-${index}`} className="pointer">
          <NavLink to={item.link} onClick={(e) => this.onNavItemClick(item.link)}>{item.label}</NavLink>
        </li>
      );
    });
    return (
      <div className={`header ${visible ? 'fadeIn' : 'opacity0'}`}>
        <div className='logo'>
          <img src={jpgLogo} alt="Logo" />
        </div>
        <div className={`hamburgerMenu ${toggleMenu ? 'crossIcon' : ''}`} onClick={this.mobileMenuClick}>
          <div className="line"></div>
          <div className="line"></div>
        </div>
        <ul>
          {navItems}
        </ul>
      </div>
    );
  }
}